<template>
    <section class="about-me">
      <h2>About Me</h2>
      <p>
        I work as a General Manager at PingPod Philly, specializing in community engagement and program development, serving as a social media strategist, and proudly representing Killerspin as a sponsored athlete. I am passionate about technology and problem-solving and strive to deliver impactful results.
      </p>
      <p>
       Through my athletic career, I have gained valuable skills such as discipline, teamwork, and a strong work ethic.
      </p>
      <p>
        By combining my technical skills with sports experience, I bring a unique perspective to my work. I am eager to connect and explore opportunities to collaborate.
      </p>
    </section>
  </template>
  
  <script>
  export default {
    name: 'AboutMe'
  }
  </script>
  
  <style scoped>
  .about-me {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(221, 21, 21, 0.1);
    font-family: 'Open Sans', sans-serif;
    max-width: 100%;
    line-height: 1.6;
    flex: 1;
  }
  
  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 2em;
    margin-bottom: 20px;
    color: #2c3e50;
  }
  
  p {
    font-size: 1.2em;
    color: #555;
  }
  </style>
  
